<template>
  <AuthPageLayout title="Авторизация по ЭЦП">
    <FormAuthByToken :sign-in="onSubmit" />
  </AuthPageLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import waitable from '@/utils/mixins/waitable';
import FormAuthByToken from '@/components/auth/ByToken';
import AuthPageLayout from '@/components/layouts/AuthPageLayout';

export default {
  components: {
    AuthPageLayout,
    FormAuthByToken,
  },

  mixins: [waitable],

  computed: {
    ...mapGetters('AUTH', ['isAuthorized']),
  },

  watch: {
    isAuthorized(value) {
      // Выполняем действие, если компонент находится на экране (keep-alive)
      if (value && !this._inactive) {
        if (this.$route.query.redirect)
          this.$router
            .push(this.$route.query.redirect)
            .catch(err => console.error(err));
        else this.$router.push({ name: 'main' });
      }
    },
  },

  methods: {
    ...mapActions('AUTH', ['signIn']),

    async onSubmit(credentials) {
      await this.signIn({
        credentials,
        type: 'cryptopro',
      });

      this.$store.dispatch('initApplication');
    },
  },
};
</script>
