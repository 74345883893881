<template>
  <v-layout fill-height justify-center align-center column>
    <div style="width: 490px; max-width: 100vw">
      <v-img
        class="ma-auto mb-3"
        src="@/assets/images/logo-full-blue.svg"
        max-width="340"
        contain
      />
      <v-card>
        <v-card-title class="mb-2 justify-center">
          {{ title }}
        </v-card-title>
        <v-card-text class="pb-0">
          <slot />
        </v-card-text>
        <v-card-actions class="d-flex justify-center flex-column">
          <slot name="actions" />
        </v-card-actions>
      </v-card>
    </div>
    <div class="mt-9 text-center text--disabled">
      {{ copyright }}
    </div>
  </v-layout>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    copyright: {
      type: String,
      default: '2017 – ' + new Date().getFullYear() + ' г. © DistMed.com',
    },
  },
};
</script>
